/* Properties Page Styles */

body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f0f2f5; /* Light gray background */
  }
  
  h1 {
    font-size: 2.5rem;
    color: #333;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    font-weight: bold;
  }
  
  .property-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid layout */
    gap: 20px;
    padding: 0 20px;
  }
  
  .property-item {
    background-color: #ffffff;
    border-radius: 10px; /* Rounded corners for modern look */
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .property-item:hover {
    transform: translateY(-10px); /* Elevated effect on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .property-item img {
    width: 100%;
    height: 200px; /* Consistent image height */
    object-fit: cover;
    border-bottom: 3px solid #ddd;
  }
  
  .property-item h2 {
    font-size: 1.8rem;
    color: #333;
    margin: 15px;
    font-weight: 600;
  }
  
  .property-item p {
    font-size: 1rem;
    color: #555;
    margin: 10px 15px;
  }
  
  .property-item p strong {
    color: #333;
  }
  
  .property-item p:last-of-type {
    margin-bottom: 20px; /* Extra margin at the bottom for spacing */
  }
  
  @media (max-width: 768px) {
    .property-item img {
      height: 180px; /* Slightly smaller images for medium screens */
    }
  
    .property-item h2 {
      font-size: 1.6rem; /* Adjusted font size */
    }
  
    .property-item p {
      font-size: 0.9rem; /* Smaller font size for better readability */
    }
  }
  
  @media (max-width: 480px) {
    .property-item img {
      height: 150px; /* Adjusted height for small screens */
    }
  
    .property-item h2 {
      font-size: 1.4rem; /* Further adjusted font size */
    }
  
    .property-item p {
      font-size: 0.8rem; /* Smaller font size for mobile screens */
    }
  }
  