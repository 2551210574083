/* General Styling */
body {
  font-family: Arial, sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.cart-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.indus-heading {
  font-size: 20px;
  color: #333;
  margin-top: 10px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.cart-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.cart-item span {
  font-size: 16px;
}

.remove-button {
  background-color: #e74c3c;
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.remove-button:hover {
  background-color: #c0392b;
}

button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2980b9;
}

.empty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.empty-container img {
  max-width: 100%;
  height: auto;
}

.empty-container p {
  font-size: 18px;
  color: #333;
  margin-top: 10px;
}

.payment-options-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-options-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.payment-options-content h2 {
  margin-bottom: 20px;
  color: #333;
}

.payment-options-content button {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.payment-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.popup-content .close-button {
  background: #e74c3c;
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.popup-content .close-button:hover {
  background: #c0392b;
}

.popup-content input {
  width: calc(100% - 22px);
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.popup-content .qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-content .qr-container h3 {
  margin-bottom: 10px;
}

.popup-content .qr-container .amount {
  margin-top: 10px;
  font-size: 18px;
  color: #333;
}

.logo-qr {
  max-width: 100px;
  height: auto;
}

.logo {
  max-width: 150px;
  height: auto;
  margin-bottom: 20px;
}
