.landing-page-screen-7-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    width: 100%;
    min-height: 85vh;
    padding: 40px 6%;
    background-color: #f9f9f9; /* Optional background for better visibility */
}

.landing-page-s7-heading {
    font-weight: 700;
    color: #1D2434;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: 2px;
    margin-bottom: 24px;
    text-align: center;
}

.landing-page-s7-category-buttons {
    display: flex;
    justify-content: center; /* Center buttons horizontally */
    gap: 20px; /* Space between buttons */
    margin-bottom: 24px;
    width: 100%; /* Ensure buttons container takes full width */
}

.category-button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    color: #1D2434;
    background-color: #fff;
    border: 2px solid #1D2434;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.category-button.active {
    background-color: #1D2434;
    color: #fff;
}

.category-button:hover {
    background-color: #f3f4f6;
    border-color: #1D2434;
}

.landing-page-s7-accordion-consultation-con {
    display: flex;
    justify-content: center; /* Center the accordion */
    margin-top: 15px;
    width: 100%;
}

.landing-page-s7-accordion-con {
    width: 60%; /* Adjust for centered layout */
    display: flex;
    flex-direction: column;
}

.landing-page-s7-accordion-item {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    margin-bottom: 20px;
    box-shadow: 0px 0px 10px 0px #0000001a;
    cursor: pointer;
}

.landing-page-s7-accordion-item-label-icon-con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.landing-page-s7-accordion-label {
    font-family: "Poppins", Sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: black;
}

.landing-page-s7-accordion-icon {
    color: #1e293b;
    font-size: 22px;
    transition: transform 0.5s ease-out;
}

.landing-page-s7-accordion-icon.active {
    transform: rotate(180deg);
}

.landing-page-s7-accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-out, padding 0.25s ease-out;
    padding: 0 10px;
}

.landing-page-s7-accordion-content-text {
    color: #000000;
    font-family: "Poppins", Sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    padding: 0 20px;
}

.active-accordion {
    max-height: 1000px;
    padding: 10px;
}

@media screen and (max-width: 768px) {
    .landing-page-s7-heading {
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 24px;
        align-self: center;
    }

    .landing-page-s7-category-buttons {
        flex-direction: column; /* Stack buttons vertically */
        gap: 10px;
    }

    .landing-page-s7-accordion-consultation-con {
        flex-direction: column;
        align-items: center;
    }

    .landing-page-s7-accordion-con {
        width: 100%;
    }
}




/* FAQ Section with Background Animation */
.landing-page-screen-7-container {
    position: relative;
    overflow: hidden;
    padding: 50px;
    text-align: center;
    color: #fff;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.2) 0%,
      #003366 100%
    ); /* Smooth transition from top to bottom */
    z-index: 1;
  }
  
  /* Animated Dots (Particles) */
  .landing-page-screen-7-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle, #ffffff44 1px, transparent 1px);
    background-size: 25px 25px; /* Adjust density */
    animation: moveParticles 15s linear infinite;
    z-index: -1;
    opacity: 0.5; /* Subtle effect */
  }
  
  /* Sliding Diagonal Lines */
  .landing-page-screen-7-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: -50%;
    width: 200%;
    height: 200%;
    background: repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05) 15px,
      transparent 15px,
      transparent 30px
    );
    animation: slideLines 12s ease-in-out infinite;
    z-index: -2;
  }
  
  /* Particle Animation */
  @keyframes moveParticles {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(-10px, -10px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  
  /* Line Slide Animation */
  @keyframes slideLines {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(150px);
    }
  }
  