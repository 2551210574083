.navbar {
  position: relative;
  background-color: #071e48;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  z-index: 1000;
}


.mobile-menu-icon {
  display: none;
}

.navbar-logo {
  display: flex;
  cursor: pointer;
  text-decoration: none;
  z-index: 1001;
  position: relative;
  margin-left: 15px;
}

.navbar-logo img {
  width: 60px;
}

.company-info {
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  margin-left: 10px; 
  text-decoration: none;
}

.company-name {
  color: white; 
  font-size: 1.5rem; 
  font-weight: bold; 
  margin-top: 10px;
}

.slogan {
  font-size: 0.5rem; 
  color: #ffffff; 
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.menu-icon-350 {
  display: none;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 45vw;
  justify-content: center;
  margin-right: 5rem; 
  padding-top: 10px;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-item-3 {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: white;
  text-decoration: none; 
  font-weight: 500;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #fff0f5;
  color: #242222;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
  position: absolute;
  right: 15px; /* Position on the right */
  top: 50%;
  transform: translateY(-50%);
  z-index: 1001;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    margin-left: 2vh;
    text-decoration: none;
  }

  .nav-links:hover {
    background-color: white;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .navbar-logo img {
    width: 50px;
  }

  .fa-times {
    color: #131111;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: white;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }
}

.desktop-img {
  width: 120px;
}

.btn-2 {
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 15px;
  color: black;
  background-color: white; 
  padding: 10px 16px; 
  margin-right: 20px;
  border: none;
  border-radius: 4px; 
  margin-top: 5px;
  margin-left: 40px; 
  width: 171px;
}

.nav-menu-mobile {
  width: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.ajay-m {
  color: #131111;
}

@media screen and (min-width: 768px) {
  .website-logo {
    width: 165px;
    border-radius: 3px;
  }
}

.user-name {
  color: #fff;
  font-size: 11px;
}

.phone-container {
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 30px;
  transition: all 0.3s ease;
}

.phone-container:hover {
  border-color: #fff;
}

.phone-text {
  font-weight: bold;
}

.phone-icon {
  font-size: 24px;
  animation: phoneIcon 1s infinite alternate;
}

@keyframes phoneIcon {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-3px);
  }
}




/* Mobile view adjustments */
@media screen and (max-width: 370px) {
  .navbar-logo {
    position: relative;
    left: 0; /* Align the logo to the left */
    top: 50%;
    transform: translateY(-50%);
    margin-left: 10px; /* Additional spacing */
  }

  .navbar-logo img {
    width: 30px;
    margin-bottom: 20px;
  }
.company-name{
  display: none;
}
  .menu-icon {
    display: block;
    position: absolute;
    right: 10px; /* Keep it on the right */
    top: 50%;
    transform: translateY(-50%);
  }
  .phone-container{
    margin-left: 30px;
  }

  .nav-menu {
    display: none;
  }
  .mobile-menu-icon {
    display: block;
    cursor: pointer;
    position: absolute;
    left: 50%;
    margin-top: 13px;
  }
  .menus{
    margin-left: 10px;
  }


  .mobile-menu-dropdown {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: 0;
    background: #1077aa;
    width: 80%;
    padding: 10px;
    z-index: 1002;
    margin-top: 30px;
    margin-right: 63px;
  }
  .nav-item{
    margin-right: 30px;
  }

  .mobile-menu-items {
    list-style: none;
    padding: 0;
  }

  .mobile-menu-link {
    padding: 10px;
    text-align: center;
    position: relative;
    margin-right: 120px;
  }
  .mobile-menu-link a {
    color: #000000;
    text-decoration: none;
  }

 
  .mobile-menu-link .dropdown-menu {
    display: none; 
    margin-top: 5px;
    padding: 10px;
    border-radius: 4px;
    position: relative;
    top: 0;
    left: 0;
  }
  .mobile-menu-link:hover .dropdown-menu {
    display: block;
  }
  .mobile-menu-link.active .dropdown-menu {
    display: block; /* Show when active */
  }

  .mobile-menu-link.active .dropdown-menu .dropdown-menu {
    position: absolute;
    left: 100%; /* Position to the right of the parent item */
    top: 0;
    .mobile-menu-link:hover .nav-links {
      color: black; /* Change text color to black on hover */
    }
  }
  .mobile-menu-dropdown .product-menu{
    width: 70px;
    margin-left: 12vh;
  }
  .mobile-menu-dropdown .service-menu{
    width: 70px;
    margin-left: 12vh;
  }
  
}




.nav-item .dropdown-menu {
  display: none;
  position: absolute;
}

.nav-item:hover .dropdown-menu {
  display: block;
}


.mobile-menu-link .dropdown-menu {
  display: none;
  background-color: #444;
  margin-top: 5px;
  padding: 10px;
  border-radius: 4px;
}


.mobile-menu-link {
  width: 100%;
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #444;
}
.mobile-menu-link .nav-links {
  color: #fff;
  cursor: pointer;
}
.menu-item {
  position: relative;
}
.menu-item .dropdown-menu {
  display: none; /* Hide by default */
  position: absolute;
  left: 100%; /* Position to the right of the parent item */
  top: 0;
  background-color: #555;
}
.menu-item .dropdown-menu {
  display: none; /* Hide by default */
  position: absolute;
  left: 100%; /* Position to the right of the parent item */
  top: 0;
  background-color: #555;
}
.product-menu {
  list-style: none; /* Remove default list styling */
  margin-top: 28px;
  margin-right: 10px;
  margin-left: 10px;
}
.service-menu {
  list-style: none; /* Remove default list styling */
  margin-top: 28px;
  margin-right: 10px;
  margin-left: 10px;
}

.products-link {
  color: #ffffff; /* White color */
  text-decoration: none; /* Remove underline */
  font-size: 1.2rem; /* Adjust font size as needed */
  cursor: pointer; /* Change cursor to pointer for clickable */
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.products-link:hover {
  background-color: #fff0f5;
  height: 80px;
  width: 80px;
  color: #242222;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}
/* WhatsApp Icon Container */
.whatsapp {
  position: relative; /* To position the chatbox relative to the icon */
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px; /* Adjust based on your navbar spacing */
  cursor: pointer;
}

/* WhatsApp Icon Styling */
.whatsapp-icon {
  font-size: 2rem; /* Icon size */
  transition: transform 0.3s ease;
}

.whatsapp-icon:hover {
  transform: scale(1.1); /* Slightly enlarge the icon on hover */
}

/* Chatbox Styling */
.whatsapp-chatbox {
  position: absolute;
  top: 100%; /* Position below the icon */
  right: 0;
  width: 300px;
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;
  z-index: 1000; /* Ensure the chatbox appears above other elements */
}

/* Chatbox Header */
.chatbox-header {
  background-color: #25D366;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.close-chatbox {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Chatbox Body */
.chatbox-body {
  padding: 10px;
}

.chatbox-body textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
}

/* Chatbox Footer */
.chatbox-footer {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.send-button {
  background-color: #25D366;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.send-button .fa-paper-plane {
  margin-left: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .whatsapp {
    margin-right: 10px;
  }

  .whatsapp-chatbox {
    width: 250px;
  }

  .whatsapp-icon {
    font-size: 1.5rem;
  }
}

.navbar-right {
  position: relative; /* Make the container relative for positioning the dropdown */
}

.menu-icon-nav {
  display: flex;
  cursor: pointer;
  color: white;
  margin-right: 50px;
}

.menu-icon-nav i{
  font-size: 30px;
}


.dropdown-menu-ham {
  position: absolute;
  top: 50px; /* Position directly below the user icon */
  right: 0;  /* Align to the right of the parent */
  background-color: #ffffff; /* White background */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  z-index: 999; /* Ensure it appears on top of other elements */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.userinfo {
  padding: 10px;
  background-color: #f9f9f9; /* Light background for the user info */
  border-radius: 8px;
}

.logout-desktop-btn {
  background-color: #d32f2f; /* Red background for the logout button */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.logout-desktop-btn:hover {
  background-color: #c62828; /* Darker red on hover */
}

/* Styling for the login button */
.login-btn {
  background-color: #0d47a1;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: block;
}

.login-btn:hover {
  background-color: #1565c0;
}

@media (max-width: 768px){
  .menu-icon-nav i {
    position: absolute;
    right: 10px; /* Pushes the user icon to the far right */
    font-size: 30px; /* Adjust the size of the icon */
    color: white; /* Icon color */
    cursor: pointer;
  }
}


.website-logo {
  display: inline-block;
  animation: cube-rotate 4s infinite;
  width: 50px; /* Set your desired width */
  height: auto;
  text-decoration: none;
}



.nav-links:hover {
  background-color: #fff0f5;
  color: #242222;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.2s ease-out;
}


