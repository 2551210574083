/* Footer Container */
.navbottom-container {
  background-color: #003366; /* Dark Blue Background */
  color: #fff; /* White Text Color */
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Footer Sections */
.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1;
  margin: 10px;
}

.footer-section h3 {
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section .li-item {
  margin: 10px 0;
}

.footer-section .li-item a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section .li-item a:hover {
  color: #ffcc00; /* Highlight Color on Hover */
}

.contact p {
  margin: 10px 0;
}

.social-media-cont {
  display: flex;
  justify-content: space-between;
  padding: 0;
  list-style: none;
}

.social-media-cont li {
  margin: 0 10px;
}

.social-img {
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease;
}

.social-img:hover {
  transform: scale(1.1); /* Zoom effect on hover */
}

/* Footer Bottom */
.copyright1 {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.follow-heading {
  margin-top: 20px;
}
/* App Download Section */
/* App Download Section */
.app-download {
  background-color: #002244; /* Slightly different dark blue for emphasis */
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin-top: 20px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.app-download:hover {
  background-color: #003366; /* Darker blue on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow on hover */
}

.app-download h3 {
  margin-bottom: 10px;
}

.app-download p {
  margin-bottom: 20px;
}

.app-link {
  text-decoration: none;
}

.download-button {
  background-color: #ffcc00; /* Highlight color for button */
  color: #003366; /* Dark blue text */
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #e6b800; /* Slightly darker yellow on hover */
}





.footer-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  }
  
  .footer-section {
  color: white;
  }
  
  .footer-section h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #fff;
  }
  
  .footer-section ul {
  list-style: none;
  padding: 0;
  
  }
  
  .footer-section ul li {
  margin-bottom: 8px;
  }
  
  .footer-section ul li a {
  color: white;
  text-decoration: none;
  text-align: left;
  }
  
  .footer-section ul li a:hover {
  color: #000;
  }
  
  .footer-section p {
  margin-bottom: 5px;
  }
  
  .social-links {
  list-style: none;
  padding: 0;
  display: flex;
  }
  
  .social-links li {
  margin-right: 10px;
  }
  
  .social-links li a {
  color: white;
  text-decoration: none;
  font-size: 1.2em;
  }
  
  .social-links li a:hover {
  color: #000;
  }
  .footer-bottom {
  text-align: center;
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid #ccc;
  }
  
  .footer-bottom p {
  font-size: 0.9em;
  color: white;
  }
  
  
  .social-buttons1 {
  margin-top: 20px;
  }
  
  .social-buttons1 button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  }
  .trigger-button {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  background-color: #ccc;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  }
  @media (max-width: 768px) {
  .register__container1 {
    width: 80%;
  }
  }
  
  @media (max-width: 576px) {
  .register__container1 {
    width: 90%;
  }
  
  .signup-heading1 {
    font-size: 20px;
  }}