.cont-1 {
  padding: 10px;
  background: rgb(0, 58, 94);
  background: linear-gradient(90deg, #0a1b3a, #1f3c5d);
  padding-bottom: 80px; /* Increase padding to avoid overlap with footer */
  color: #ffffff; /* Ensure text is readable */
}

.about-us {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 40px; /* Add margin to the bottom */
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent white */
  backdrop-filter: blur(10px); /* Apply a blur effect to the background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Light border to enhance the mirror effect */
}


.about-us h1 {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-family: 'PT Sans', sans-serif;
}

.about-us p {
  font-size: 1rem;
  line-height: 1.6;
  color: #e6f6ff;
  font-family: 'PT Sans', sans-serif;
  font-weight: 450;
  margin-bottom: 15px;
}

.about-us p:first-child {
  font-weight: bold;
  text-decoration: underline;
}

.about-cont img {
  height: 150px;
  width: 150px;
  margin-left: 380px;
  margin-bottom: 10px;
}

.about-cont {
  background-color: #ffffff;
  padding: 25px;
  max-width: 1000px;
  border-radius: 6px;
  margin-bottom: 20px;
}

/* Media query for responsiveness */
@media screen and (max-width: 768px) {
  .about-us {
      padding: 15px;
      border-radius: 0;
  }

  .about-us h2 {
      font-size: 1.8rem;
  }

  .about-us p {
      font-size: 0.9rem;
  }
}

/* Container for mission and vision sections */
.mission-vision-container {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Adjust gap as needed */
}
.mission-vision {
  flex: 1;
  width: 130vh;
  margin: 20px;
  margin-left: 8vh;
  padding: 20px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Light border */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.mission-vision:hover {
  transform: scale(1.05); /* Slightly enlarge the element */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow effect */
}

/* Heading Styles */
.mission-vision h2 {
  font-size: 2em;
  color: #7da8d2;
  margin-bottom: 10px;
  margin-left: 1vh;
  opacity: 0;
  animation: slideInFromLeft 1s ease-out forwards;
}

/* Paragraph Styles */
.mission-vision p {
  font-size: 1.2em;
  color: #e6f6ff;
  line-height: 1.6;
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
}



/* Keyframes for heading animation */
@keyframes slideInFromLeft {
  from {
    transform: translateX(-20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Keyframes for paragraph animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


@media screen and (max-width: 370px) {
  .about-us {
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 60px; /* Ensure space at the bottom */
      text-align: center; /* Center align text */
      border-radius: 10px;
  }

  .about-us h1 {
      font-size: 1.4rem; /* Adjust the font size */
  }

  .about-us p {
      font-size: 0.8rem; /* Adjust the font size */
  }

  .about-cont img {
      height: 80px;
      width: 80px;
      margin-left: auto;
      margin-right: auto;
      display: block;
  }

  .about-cont {
      padding: 15px;
  }
}
@media screen and (max-width: 370px) {
  .mission-vision-container {
    flex-direction: column; /* Stack items vertically */
    gap: 10px; /* Adjust gap for very small screens */
  }

  .mission-vision {
    width: 100%; /* Ensure full width */
    margin: 10px auto; /* Center and provide margin */
    padding: 10px; /* Adjust padding */
  }

  .mission-vision h2 {
    font-size: 1.4rem; /* Adjust font size for small screens */
    margin-bottom: 8px; /* Adjust margin */
  }

  .mission-vision p {
    font-size: 0.9rem; /* Adjust font size for small screens */
  }
}
