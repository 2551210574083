/* Title Search Container */
.title-search-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

/* Main Heading Style */
.title-search-container h1 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* Paragraph Style */
.title-search-container p {
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: justify;
  color: #666;
}

/* Container Wrapper Style */
.ts-containers-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 10px;
}

/* Half Container Style */
.ts-half-container {
  width: 40%; /* Adjust width for smaller screens */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Hide overflowing content */
}

/* Image Style */
.ts-half-container img {
  width: 85%;
  height: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* Heading Style */
.ts-half-container h2 {
  color: royalblue;
  font-size: 1.2rem;
  margin: 15px;
}

/* Paragraph Style within Half Container */
.ts-half-container p {
  color: #444;
  font-size: 1rem;
  margin: 0 15px 15px;
}
