/* General Styles */
body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f0f2f5;
  color: #333;
}

.legal-services-container {
  padding: 30px;
  max-width: 1200px;
  margin: auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.main-heading {
  text-align: center;
  color: #003366;
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.intro {
  text-align: center;
  margin-bottom: 30px;
}

.legal-sub-heading {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;
  margin: 0 15px;
}

.container-legal {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 20px;
}

.left-container, .right-container {
  flex: 1;
  margin: 10px;
}

.left-container img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.right-container {
  max-width: 600px;
}

.right-container h2 {
  color: #003366;
  margin-bottom: 15px;
  font-size: 1.8rem;
  font-weight: bold;
}

.right-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.right-container ul li {
  background: #e7eff4;
  border: 1px solid #ddd;
  margin-bottom: 8px;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.right-container button {
  background: linear-gradient(135deg, #003366, #0066cc);
  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease;
}

.right-container button:hover {
  background: linear-gradient(135deg, #0066cc, #003366);
}

.notification {
  background: #eaf8e0;
  border: 1px solid #b6d6a3;
  padding: 15px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.notification p {
  margin: 0;
  font-size: 1rem;
  color: #333;
}

.notification button {
  background: #003366;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease;
}

.notification button:hover {
  background: #002244;
}

.services-cont {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.service-1 {
  flex: 1;
  margin: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  padding: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-1:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-1 img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
}

.service-1 h2 {
  color: #003366;
  margin: 15px 0;
  font-size: 1.5rem;
  font-weight: bold;
}

.service-1 p {
  color: #666;
  font-size: 0.9rem;
  line-height: 1.5;
}

.service-1 button {
  background: linear-gradient(135deg, #003366, #0066cc);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 10px;
  transition: background 0.3s ease;
}

.service-1 button:hover {
  background: linear-gradient(135deg, #0066cc, #003366);
}

@media (max-width: 768px) {
  .container-legal {
    flex-direction: column;
    align-items: center;
  }

  .left-container, .right-container {
    max-width: 100%;
  }

  .services-cont {
    flex-direction: column;
  }
}

@media (max-width: 370px) {
  .main-heading {
    font-size: 1.8rem;
  }

  .legal-sub-heading {
    font-size: 1rem;
  }

  .right-container button {
    padding: 8px 15px;
    font-size: 0.9rem;
  }

  .notification button {
    padding: 8px 15px;
    font-size: 0.9rem;
  }

  .service-1 h2 {
    font-size: 1.2rem;
  }

  .service-1 p {
    font-size: 0.8rem;
  }
}
