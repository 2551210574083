/* Base styles */
.contact-us-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.contact-info {
  flex: 1;
  margin-right: 20px;
  text-align: center;
}

.contact-info img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.contact-info h2 {
  margin-bottom: 10px;
  font-size: 2rem;
  color: #333;
}

.contact-info p {
  color: #555;
}

.contact-details {
  margin-top: 20px;
}

.contact-details p {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #333;
}

.social-media-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
  margin-top: 20px;
}

.social-media-buttons a,
.social-media-buttons a img {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.social-media-buttons a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.social-media-buttons a:hover img {
  transform: scale(1.1);
}

.contact-form {
  flex: 1;
  margin-left: 20px;
}

.contact-form h3 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

input[type='text'],
input[type='email'],
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

textarea {
  resize: vertical;
  min-height: 150px;
}

button[type='submit'] {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type='submit']:hover {
  background-color: #0056b3;
}

.cont1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: rgb(10, 45, 85);
  background: linear-gradient(90deg, rgba(10, 45, 85, 1) 0%, rgba(0, 105, 190, 1) 50%, rgba(10, 45, 85, 1) 100%);
  color: #ffffff; /* Ensure text stands out against the background */
}

.contact-us-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 85%;
  background-color: #fff;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px;
}

/* Left section - Contact Info */
.contact-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-info h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #333;
}

.contact-info p {
  font-size: 1.1rem;
  text-align: center;
  color: #555;
}

.contact-details {
  margin-top: 20px;
  text-align: center;
}

.contact-details p {
  margin: 5px 0;
  font-size: 1.1rem;
  color: #333;
}

/* Right section - Contact Form */
.contact-form {
  flex: 1;
  padding-left: 30px;
}

.contact-form h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

input[type='text'],
input[type='email'],
textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  resize: vertical;
}

button[type='submit'] {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #3498db;
  color: white;
  cursor: pointer;
}

button[type='submit']:hover {
  background-color: #2980b9;
}

.list-el {
  text-decoration: none;
}

.address-details {
  border: 3px solid #ccc;
  padding: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.address-details h2 {
  color: #333;
  font-size: 2em;
  margin-bottom: 5px;
}

.address-details p {
  color: #666;
  font-size: 1em;
  margin-bottom: 5px;
}

.address-img {
  width: 100px;
  height: 100px;
}

.ajay {
  background-color: black;
  padding: 10px;
}

/* Media queries for smaller screens */
@media (max-width: 370px) {
  .contact-us-container {
    flex-direction: column; /* Stack elements vertically */
    padding: 15px;
    width: 100%; /* Full width */
  }

  .contact-info, .contact-form {
    margin: 0;
    width: 100%;
    padding: 0;
  }

  .contact-info h2 {
    font-size: 1.8rem; /* Smaller font size */
  }

  .contact-info p {
    font-size: 1rem; /* Smaller font size */
  }

  .contact-form h3 {
    font-size: 1.5rem; /* Smaller font size */
  }

  input[type='text'],
  input[type='email'],
  textarea {
    font-size: 0.9rem; /* Smaller font size */
    padding: 8px; /* Smaller padding */
  }

  textarea {
    min-height: 100px; /* Adjust minimum height */
  }

  button[type='submit'] {
    font-size: 0.9rem; /* Smaller font size */
    padding: 8px 16px; /* Smaller padding */
  }

  .address-details {
    padding: 15px; /* Smaller padding */
  }

  .address-details h2 {
    font-size: 1.5em; /* Smaller font size */
  }

  .address-details p {
    font-size: 0.9em; /* Smaller font size */
  }

  .social-media-buttons a {
    width: 40px; /* Smaller icon size */
    height: 40px;
  }
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* Aspect ratio: 16:9 (height / width * 100) */
  height: 0;
  overflow: hidden;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

