.privacy-policy-page {
 
  min-height: 87vh;
  background-color: white;
}

.privacy-policy-page__background {
  background-color: #e6f6ff;
  height: 12vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.privacy-policy-heading {
  font-size: 20px;
  font-weight: 700;
  color:#333;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.privacy-policy-page__content {
  padding: 50px 10%;
}

.privacy-policy-content-sub {
  margin-bottom: 25px;
}

.privacy-policy-subheading {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.6;
  color: #000;
  margin-bottom: 14px;
  text-align: justify;
}

.privacy-policy-list {
  list-style-type: none;
  padding-left: 0;
}

.privacy-policy-item {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  color: #6d6d6d;
  margin-bottom: 9px;
  text-align: justify;
}

.privacy-policy-item-span {
  font-weight: 700;
  margin-right: 6px;
}

.privacy-policy-founder {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}

.privacy-policy-image-con {
  width: 30%;
}

.privacy-policy-founder-image {
  width: 100%;
  border-radius: 15px;
}

.privacy-policy-founder-name {
  font-size: 14px;
  font-weight: 700;
  color: #EB6A4D;
  margin-top: 10px;
  text-align: center;
}

.privacy-policy-founder-title {
  font-size: 12px;
  font-weight: 400;
  color: #6d6d6d;
  text-align: center;
  margin-top: 5px;
}

.founder-content {
  width: 70%;
  margin-right: 25px;
  margin-top: 15px;
}

.strong-text {
  font-weight: 700;
  color: #EB6A4D;
}

.privacy-policy-founder-linkedin {
  text-decoration: none;
  margin-top: 6px;
  width: 100%;
  display: block;
}

.linkedin-icon {
  font-size: 18px;
  color: #EB6A4D;
  margin: auto;
  display: block;
}

@media screen and (max-width: 768px){
  .privacy-policy-page {
      min-height: 90vh;
  }

  .privacy-policy-page__content {
      padding: 50px 6%;
  }

  .privacy-policy-founder {
      flex-direction: column;

  }

  .privacy-policy-image-con {
      width: 100%;
  }

  .privacy-policy-founder-name {
      text-align: left;
    
  }

  .founder-content {
      width: 100%;
      margin-right: 0;
      margin-top: 15px;
  }

  .privacy-policy-founder-title {
      text-align: left;
      margin-top: 5px;
  }

  .privacy-policy-founder-linkedin {
      margin-top: 6px;
      width: 100%;
      display: block;
  }

  .linkedin-icon {
      margin: 0;
  }

}