.property-list {
  padding: 20px;
}

.city-section {
  margin-bottom: 40px;
}

.properties-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.property-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  width: calc(33.333% - 20px); /* Adjust the width as per your design */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.property-card:hover {
  transform: scale(1.05);
}

.property-photo {
  width: 100%;
  height: 200px; /* Adjust the height as per your design */
  object-fit: cover;
}

.property-card h3 {
  margin: 16px;
  font-size: 1.25em;
}

.property-card p {
  margin: 0 16px 16px;
}

.price {
  font-weight: bold;
  color: #ff5722;
}
