.blogs-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.main-heading-blog {
  font-size: 36px;
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 40px;
  font-family: 'PT Sans', sans-serif;
}

.blogs-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.blog-info-item {
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid;
}

.blog-info-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.blog-item-image {
  width: 100%;
  height: auto;
  transition: opacity 0.3s ease;
}

.blog-item-image:hover {
  opacity: 0.85;
}

.blog-info-content {
  padding: 20px;
}

.blog-details-title-list {
  font-size: 24px;
  color: #333333;
  margin-bottom: 10px;
  font-family: 'PT Sans', sans-serif;
}

.author-details {
  font-size: 14px;
  color: #999999;
  margin-bottom: 15px;
}

.blog-item-content {
  font-size: 16px;
  color: #666666;
  line-height: 1.6;
  font-family: 'PT Sans', sans-serif;
}

@media screen and (max-width: 768px) {
  .main-heading-blog {
    font-size: 28px;
  }

  .blogs-list {
    gap: 20px;
  }

  .blog-info-content {
    padding: 15px;
  }

  .blog-details-title-list {
    font-size: 20px;
  }

  .blog-item-content {
    font-size: 14px;
  }
}
.blog-info-item p{
  margin-left: 10px;
}