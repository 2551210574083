/* Categories.css */
.category-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .category-buttons a {
    display: block;
    padding: 10px;
    margin: 5px;
    border: 1px solid #3498db;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    color: #3498db;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .category-buttons a:hover {
    background-color: #3498db;
    color: #fff;
  }
  