.popup-content {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  max-width: none; /* Remove any max-width limitations */
  width: 90% !important; /* Force the popup to take 90% of the viewport width */
  max-width: 900px !important; /* Set a new max-width to limit it on larger screens */
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
}

/* Image inside the popup */
.popup-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}

/* Close button styling */
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  color: #333;
}

/* Heading inside the popup */
.popup-content h2 {
  font-size: 28px;
  margin: 20px 0;
  color: #333;
} 

/* Paragraph inside the popup */
.popup-content p {
  font-size: 18px;
  color: #555;
  margin-bottom: 25px;
}

/* 
.home-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to right, #fdb44b, #fc5c7d);
  background-size: cover;
}

.home-content {
  text-align: center;
  color: white;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5); 
  border-radius: 10px;
  max-width: 100vh;
  
} */

.service-heading {
  width: 100%;
  height: 50px;
  font-family: 'PT Sans', sans-serif;
  color: #ffffff; 
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 20px; 
}

#services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  background: linear-gradient(135deg, #041e3f 20%, #6a8eb2 80%); 
}

.service-info-home {
  display: inline-block;
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5), inset 0 0 15px rgba(255, 255, 255, 0.2); /* Inner shadow for glass effect */
  backdrop-filter: blur(10px); /* Glassy effect */
  border-radius: 6px;
  padding: 20px;
  /* margin: 20px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.service-info-home img{
  height: 200px;
}

.service-info-home h1{
  color: white;
}
.service-info-home p{
  color: white;
}

.service-info-home:hover {
  transform: translateY(-5px); /* Subtle hover effect */
}



.service-details-title-home {
  color: #1e293b; /* Darker text color for contrast */
  font-size: 1.5em;
  font-weight: bold;
}

.service-content {
  color: #333; 
  font-size: 1em;
}

.product-sections {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 90%;
  max-width: 1110px;
}

@media screen and (min-width: 768px) {
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-content1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
}

.cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.home-content1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: right;
}

@media screen and (min-width: 768px) {
  .home-content1 {
    align-items: flex-end;
  }
}


.home-description1 {
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 28px;
  color: #64748b;
  margin-bottom: 0;
  text-align: end;
}


.service-item h3 {
margin: 10px 0;
color: #333; 
}

.service-item p {
color: #666;
}
@keyframes fadeIn {
from {
  opacity: 0;
  transform: translateY(15px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
}

.service-heading {
font-family: 'PT Sans', sans-serif;
margin-top: 0px;
font-size: 30px;
color: #1e293b;
font-weight: 600;
text-align: center;
}


.content-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 15px;
  position: relative;
  overflow: hidden;
}

.column {
  text-align: center;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  transition: transform 0.4s ease, background-color 0.4s ease, box-shadow 0.4s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.column:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.column:hover::before {
  transform: translate(-50%, -50%) scale(1.2);
  opacity: 0.4;
}

.highlight {
  font-size: 1.8rem;
  font-weight: bold;
  color: #12124b; 
  margin-bottom: 10px;
  z-index: 2;
  position: relative;
}

.details {
  font-size: 1.2rem;
  color: #00aaff; /* Light blue color for better visibility */
  margin: 5px 0;
  transition: color 0.4s ease, transform 0.3s ease;
  z-index: 2;
  position: relative;
}

.details:hover {
  color: #0077cc;
  transform: translateY(-3px);
}

/* Responsive styles for screens 370px wide or less */
@media (max-width: 370px) {
  .content-section {
    flex-direction: column;
    padding: 10px;
    
  }

  .column {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }

  .column:hover {
    transform: none; /* Disable hover transformation for small screens */
    background: rgba(255, 255, 255, 0.4);
  }

  .highlight {
    font-size: 1rem;
  }

  .details {
    font-size: 0.8rem;
  }
}


@media screen and (max-width: 370px) {
  .home-container {
    height: auto; /* Allow height to adjust based on content */
    padding: 20px; /* Add padding to ensure content is not too close to edges */
  }

  .home-content {
    max-width: 100%; /* Ensure content takes up full width */
    padding: 10px; /* Adjust padding for smaller screens */
    margin-left: 0; /* Remove left margin */
    margin-bottom: 10px; /* Reduce bottom margin */
    margin-right: 10px;
  }

  .home-heading {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  .home-description {
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .home-button {
    font-size: 0.9rem; /* Adjust button font size for smaller screens */
    padding: 8px 16px; /* Adjust padding for smaller screens */
  }
  .blog-heading{
    font-family: 'Roboto', sans-serif;
    padding-top: 15px;
    color: #302e33;
    font-size: 32px;
    margin-left: 40%;
  }
  .service-info-home{
    width: 300px;
  }
}

.service-heading{
  font-family: 'Roboto', sans-serif;
  padding-top: 15px;
  color: white;
  font-size: 32px;
}
 .service-info-home {
  margin: 0 auto;
  max-width: 450px; /* Adjust the max-width as needed */
  text-align: center; /* Center the content horizontally */ 
  padding-top: 40px; 
  margin-bottom: 30px;  
  background: rgba(53, 18, 18, 0.2);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5), inset 0 0 15px rgba(255, 255, 255, 0.2); /* Inner shadow for glass effect */
  backdrop-filter: blur(10px); /* Glassy effect */
  margin-top: 40px;
  border-radius: 6px;
}
/* .services-container{
  background-color: #0e1341;
  display: flex;
} */

 .service-image {
  display: block; 
  width: 300px; 
  height: 250px; 
  margin: 0 auto; 
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.service-heading-container{
  background: linear-gradient(135deg, #041e3f, #6a8eb2);
}


#product-section {
  padding: 20px;
  background: linear-gradient(135deg, #041e3f 0%, #6a8eb2 100%); /* Smooth gradient */
  height: auto; /* Adjust height to fit content */
  margin-bottom: 0px; /* Add margin to prevent overlap */
  color: #ffffff; /* Ensure text is readable on gradient */
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.2); /* Optional: Add an inset shadow for depth */
}


.products-heading {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #859eb7;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: fadeIn 2s ease-in-out;
}

.products-row {
  display: flex;
  justify-content: center;
  gap: 100px;
  margin-top: 20px;
  flex-wrap: wrap;
  animation: slideIn 1.5s ease-in-out;
}

.product-column {
  flex: 1;
  max-width: 280px; /* Ensures the columns stay aligned in a row */
  text-align: center;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 30vh;
}

.product-column img {
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.product-column h3 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 20px;
  color: #34495e;
}

.product-column:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.product-column img:hover {
  transform: scale(1.1);
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


/* Overlay Styles */
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

@media (max-width: 370px) {
  #product-section {
    padding: 10px; /* Adjust padding for smaller screens */
    margin-bottom: 30px; /* Ensure space between sections */
  }

  .products-row {
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Adjust gap between rows */
  }

  .product-column {
    width: 100%;
    max-width: 300px; /* Adjust max-width for smaller screens */
    margin: 5px 0; /* Adjust spacing as needed */
  }

  .options-popup {
    width: 90%;
    max-width: 400px; /* Adjust max-width for smaller screens */
    margin: 0 auto;
  }
}


.home-heading{
  font-size: 40px; 
  color: white;
}


.home-container-2 {
  display: flex;
  align-items: center; 
  justify-content: space-around; 
  
}

.home-image {
  width: 250px;
  height: auto; 
  border: 2px solid #ccc; 
  border-radius: 5px; 
}

.radio-container {
  display: flex;
  flex-direction: column; 
  align-items: flex-start; 
  gap: 10px; 
}


#main-heading{
  font-size: 20px;
  color: #000000;
}

.home-container-1{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0056b3;
  height: 100vh;
}

.content{
  display: flex;
  align-items: center;
}

/* Main Container */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f8f9fa;
}

/* Heading */
#main-heading {
  text-align: center;
  font-size: 2.1rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
  padding-top: 5px;
}

/* Content Row */
.home-container-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  width: 100%;
  max-width: 1200px;
}

/* Images */
.home-image {
  width: 350px;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
}

/* Radio Button Container */
.radio-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.radio-container label {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  display: flex;
  align-items: center;
}

.radio-container input[type="radio"] {
  margin-right: 0.5rem;
  transform: scale(1.2);
}


@media (max-width: 768px) {
  .home-container-2 {
    flex-direction: column;
    align-items: center;
  }

  .home-image {
    width: 150px;
  }

  #main-heading {
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
    padding-top: 5px;
  }
  
}


.search-input {
  width: 80%;
  max-width: 500px;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.search-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
} 

.location-dropdown {
  width: 80%;
  max-width: 300px;
  margin: 20px auto;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: block;
}


.in {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px; /* Space between search and dropdown */
 
}

.search-input {
  width: 80%;
  max-width: 650px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.search-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.location-dropdown {
  width: 80%;
  max-width: 400px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.location-dropdown:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}


/* Features Section */
.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 2rem;
  background: #f5f5f5;
}

.feature-card {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feature-card h2 {
  margin: 1rem 0;
}


/* Features Section */
.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 2rem;
  background: linear-gradient(to right, #ffecd2, #fcb69f); /* Soft gradient */
}

/* Individual Feature Card */
.feature-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
}

.feature-icon {
  width: 80px;
  height: 80px;
  margin: 0 auto 1rem;
  background-color: #e0e0e0;
  border-radius: 50%;
}

.synthetic-data {
  background: url("https://img.freepik.com/premium-photo/modern-creative-communication-internet-network-connect-smart-city_31965-20511.jpg") no-repeat center/cover;
}

.ai-solutions {
  background: url("https://img.freepik.com/free-photo/technology-hologram-illustrated_23-2151877720.jpg") no-repeat center/cover;
}

.ar-vr {
  background: url("https://img.freepik.com/premium-photo/architect-man-vr-architecture-model-construction-building-with-future-technology-ux-virtual-reality-goggles-design-engineering-metaverse-simulation-property-development_590464-163732.jpg") no-repeat center/cover;
}

.iot {
  background: url("https://img.freepik.com/free-vector/isometric-smart-home-management-concept_1284-38331.jpg") no-repeat center/cover;
}

/* Impact Section */
.impact {
  padding: 2rem;
  text-align: center;
  background: #7b1fa2;
  color: white;
}

.impact h2 {
  font-size: 2rem;
}

.impact p {
  margin-top: 1rem;
  line-height: 1.6;
}

.impact-image {
  margin: 1.5rem auto 0;
  width: 80%;
  height: 200px;
  background: url("https://via.placeholder.com/600x200") no-repeat center center/cover;
  border-radius: 8px;
}

.input-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px; /* Reduced space between rows */
  padding: 10px; /* Add some padding to the container */
  max-width: 200px; /* Limit the width of the container */
  padding-left: 200px;
}

.input-row {
  display: flex;
  gap: 5px; /* Reduced space between elements in a row */
}

.action-button {
  padding: 8px 16px; /* Smaller padding for a compact look */
  font-size: 14px; /* Slightly smaller font size */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.action-button:hover {
  background-color: #0056b3;
}

.location-dropdown {
  padding: 8px; /* Reduced padding */
  font-size: 14px; /* Smaller font size */
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 120px; /* Narrower dropdown */
}

/* Media Queries */

/* Mobile (Portrait) */
@media (max-width: 480px) {
  .input-section {
    gap: 10px;
    padding: 8px; /* Adjust padding for small screens */
  }

  .input-row {
    flex-direction: column;
    gap: 10px; /* Stack input fields vertically */
    align-items: flex-start;
  }

  .location-dropdown {
    width: 100%; /* Full width for dropdowns on small screens */
  }

  .action-button {
    width: 100%; /* Full width for action buttons on small screens */
  }
}

/* Tablet (Portrait and Landscape) */
@media (max-width: 768px) {
  .input-section {
    gap: 15px;
  }

  .input-row {
    flex-direction: row;
    gap: 10px;
    justify-content: center;
  }

  .location-dropdown {
    width: 150px;
  }

  .action-button {
    font-size: 16px; /* Adjusted font size for buttons */
    width: 150px; /* Slightly wider buttons */
  }
}

/* Desktop (Large Screens) */
@media (min-width: 1024px) {
  .input-section {
    gap: 20px;
  }

  .input-row {
    flex-direction: row;
    gap: 15px;
    justify-content: center;
  }

  .location-dropdown {
    width: 120px; /* Slightly wider dropdown */
  }

  .action-button {
    font-size: 16px; /* Uniform button text */
  }
}

.home-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; 
  background: linear-gradient(to right, #fdb44b, #fc5c7d);
  gap: 20px;
}

.search-input {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

#main-heading {
  text-align: center;
  font-size: 24px;
  color: #333;
}

.input-section {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input-row {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.action-button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.action-button:hover {
  background-color: #0056b3;
}

.location-dropdown {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: 1;
  max-width: 150px;
}

.radio-container {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.radio-container label {
  font-size: 14px;
  color: #333;
}

.home-image {
  margin-top: 20px;
  max-width: 100%;
  border-radius: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .input-row {
    flex-direction: column;
    gap: 10px;
  }

  .radio-container {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 480px) {
  #main-heading {
    font-size: 18px;
  }

  .search-input {
    font-size: 14px;
  }

  .action-button {
    font-size: 12px;
    padding: 6px 12px;
  }

  .location-dropdown {
    font-size: 12px;
  }
}



.main-heading-view{
  background-color: #68aef8;
  color: white; 
  border-radius: 8px;
  padding: 8px; 
  font-size: 22px;
  }
  .popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  }
  
  .popup-option-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }
  
  .popup-option-item:hover {
    background-color: #434952;
  }
  
  .popup-option-image {
    width: 250px;  /* Adjust size as needed */
    height: 90px;
    margin-right: 15px;
    border-radius: 5px; /* Rounded corners for a cleaner look */
    object-fit: cover;
  }
  
  .popup-option-heading {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    flex-grow: 1;
  }
  
  
  