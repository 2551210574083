/* Service Section */
.services-section-home {
    padding: 50px 0;
  }
  
  /* Service Card */
  .service-card-home {
    text-align: center;
    animation: slideIn 1.5s ease-in-out infinite alternate;
  }
  
  /* Service Info */
  .service-info {
    margin: 0 auto;
    max-width: 600px;
    padding: 0 20px;
  }
  
  /* Service Image */
  .service-image {
    width: 100%;
    max-width: 300px;
    height: auto;
  
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .service-image:hover {
    transform: scale(1.05);
  }
  
  /* Service Title */
  .service-details-title-home {
    font-size: 1.3rem;
    color: #333;
    margin-top: 20px;
    font-family: 'PT Sans', sans-serif;
  }
  
  .service-content {
    color: #666;
    font-size: 1rem;
    font-family: 'PT Sans', sans-serif;
    line-height: 1.5;
    padding-top: 5px;
  }
  
  /* Slider Arrows */
  .slick-prev,
  .slick-next {
    font-size: 24px;
    color: #333;
    background: rgba(255, 255, 255, 0.7);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  
  .slick-prev:hover,
  .slick-next:hover {
    background: rgba(255, 255, 255, 0.9);
  }
  
  .slick-prev {
    left: 10px;
  }
  
  .slick-next {
    right: 10px;
  }
  
  /* Animation */
  @keyframes slideIn {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  
  
  /* Styles specific to the Services slider */
.services-slider .service-info {
  margin: 0 auto;
  max-width: 300px; /* Adjust the max-width as needed */
  text-align: center; /* Center the content horizontally */ 
  padding-top: 40px; 
  margin-bottom: 30px;  
  background-color: white;
  margin-top: 40px;
  border-radius: 6px;
}
.services-slider{
  background-image:  linear-gradient(70deg, #fc466b 0%, #3f5efb 100%);
}


.services-slider .service-info {
  margin: 0 auto;
  width: 300px; 
  height: 420px; 
  text-align: center; 
  padding-top: 40px;
  margin-bottom: 35px;
  background-color: white;
  margin-top: 40px;
  border-radius: 6px;
}

.services-slider .service-image {
  display: block; 
  width: 250px; 
  height: 200px; 
  margin: 0 auto; 
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

@media only screen and (max-width: 768px) {

  .service-info {
    max-width: 80%; 
    text-align: center;  
  }
  
  .service-image {
    max-width: 100%;
  } 
  .service-details-title-home {
    font-size: 15px;
    color: #333;
    margin-top: 20px;
    font-family: 'PT Sans', sans-serif;
  }
  
  .service-content {
    color: #666;
    font-size: 0.7rem;
    font-family: 'PT Sans', sans-serif;
  }
}

.service-info-container {
  position: relative;
  display: inline-block;
}

.free-banner {
  position: absolute;
  top: 0;
  right: 0;
  background-color: green; /* Adjust as needed */
  color: #ffffff; /* Adjust as needed */
  margin-top: 40px; 
  padding: 8px;
  width: 60px;
  text-align: center;
  margin-right: 30px;
  font-size: 12px;
  border-radius: 5px;
  font-weight: 600;
}
.free-banner{
  background: linear-gradient(90deg, rgba(255,255,255,0.4), transparent, rgba(255,255,255,0.4)); 
background-size: 300% 100%;
background-color: #4CAF50;
animation: shine 2s infinite linear;
}


@keyframes shine {
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
  } 