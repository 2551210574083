/* Style for the container of the entire blog */
.blog-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Style for the headings */
  .blog-main-heading {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #323f4b;
    text-decoration: none;
  }
  
.blog-sub-heading {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #444; 
    text-decoration: none;
  }
  .blog-sub-sub-heading{
    margin-top: 0;
    margin-bottom: 5px;
  }
  
  /* Style for the paragraphs */
  .blog-des {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #666;
    margin-bottom: 20px;
  }
  
  /* Style for the property image */
  .blog-container img {
    width: 90%;
    height: auto;
    margin-bottom: 20px;
    margin-left: 40px;
  }
  
  /* Style for the divs containing text */
  .blog-container > div {
    margin-bottom: 40px;
  }
  
  /* Style for links */
  a {
    color: #007bff;
    text-decoration: none;
  }
  
 .blog-sub-sub-heading{
    margin-bottom: 20px;
 }
  
  /* Media query for responsiveness */
  @media screen and (max-width: 600px) {
    .blog-container {
      padding: 10px;
    }
    
    .blog-main-heading {
      font-size: 2rem;
    }
    
    .blog-sub-heading {
      font-size: 1.8rem;
    }
    
    .blog-des {
      font-size: 1rem;
    }
  }