/* General Styles */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

/* Full-Screen Background */
.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: url('../Assets/coverimage.jpeg') no-repeat center center fixed;
  background-size: cover;
  position: relative;
}

/* Transparent Login Container */
.login__container {
  background: rgba(255, 255, 255, 0.8); /* White background with 80% opacity */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

/* Heading Style */
.login-heading {
  font-size: 2rem;
  margin-bottom: 20px;
}

/* TextBox Style */
.login__textBox {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Button Style */
.login__btn {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007f66;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  margin: 10px 0;
}

/* Button Hover Style */
.login__btn:hover {
  background-color: #005b44;
}

/* Error Message Style */
.error-message {
  color: red;
  margin: 10px 0;
}

/* Row Lines Style */
.row-lines {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.row-lines hr {
  margin: 0 10px;
}

/* Social Buttons Style */
.social-buttons {
  margin: 20px 0;
}

.google-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #db4437;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.google-button img {
  margin-right: 10px;
}

/* Link Style */
a {
  color: #007f66;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
