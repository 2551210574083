/* .post-property-container {
  display: flex;
  flex-direction: flex;
  align-items: center;
  padding: 20px;
}

.text-and-image {
  text-align: center;
  margin-bottom: 20px;
}

.text-and-image h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.form-container {
  width: 70%;
 
  max-width: 600px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.user-type,
.purpose,
.contact-info {
  margin-bottom: 20px;
}

.user-type p,
.purpose p {
  font-weight: bold;
}

.user-type button,
.purpose button {
  margin-right: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.user-type .blue-button {
  background-color: #3498db;
  color: #fff;
}

.user-type .white-button {
  background-color: #fff;
  color: #333;
  border: 1px solid #ccc;
}

.contact-info label {
  display: block;
  margin-bottom: 10px;
}

.contact-info select,
.contact-info input {
  width: calc(100% - 40px);
  padding: 8px;
  margin-top: 5px;
}

.submit-button {
  background-color: #27ae60;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
}

.social-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.google-button,
.facebook-button,
.linkedin-button {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px 16px;
  margin-bottom: 10px;
  cursor: pointer;
}

.google-button img,
.facebook-button img,
.linkedin-button img {
  width: 20px;
  margin-right: 10px;
}


.img101{
  width:600px;
  height: 500px;
  margin: 20px;
}

.blue-button {
  background-color: blue;
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 5px;
  cursor: pointer;
}

.white-button {
  background-color: white;
  color: black;
  border: 1px solid black;
  padding: 8px 16px;
  margin: 5px;
  cursor: pointer;
} */

/* src/PostProperty.css */

.property-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.property-heading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.property-form .form-group {
  margin-bottom: 15px;
}

.property-form .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.property-form .form-group input,
.property-form .form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.property-form .form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.property-form .form-group input[type="file"] {
  padding: 5px;
}

.submit-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #0056b3;
}
