/* Reset some default margin and padding */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


/* Container for the form */
.form-house-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 2rem;
    background-color: #eef2f7;
}

/* Form styling */
form {
    background-color: rgb(19, 35, 66);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
}

/* General input and label styling */
label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: block;
    color: #ffffff;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
select {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease;
}

input:focus,
select:focus {
    border-color: #007bff;
    outline: none;
}

/* Button styling */
button[type="submit"] {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
    background-color: #0056b3;
}

/* Adjustments for mobile */
@media (max-width: 768px) {
    .form-house-container {
        padding: 1rem;
    }

    form {
        padding: 1.5rem;
    }
}
