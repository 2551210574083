.payment-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  width: 90%;
  max-width: 500px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.logo {
  width: 300px;
  margin-top: 20px;
} 

.logo-qr{
  width: 200px;
  height: 100px;
}

.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr-header {
  margin-bottom: 10px;
}

.qr-header h3 {
  margin: 0;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 1.2rem;
  color: #007bff;
}

.amount {
  font-size: 18px;
  margin: 10px 0;
}

input {
  display: block;
  width: 80%;
  margin: 10px auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.success-message {
  color: green;
  font-size: 18px;
  margin-top: 20px;
}

.failure-message {
  color: red;
  font-size: 18px;
  margin-top: 20px;
}
