.dropdown-menu3 {
  background: #006ac2;
  width: 200px;
  position: absolute;
  top: 100%;
  list-style: none;
  text-align: start;
  z-index: 1;
}

.dropdown-menu3 li {
  background: #006ac2;
  cursor: pointer;
}

.dropdown-menu3 li:hover {
  background: #5cabff;
}

.dropdown-menu3.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none; /* Ensures no underline */
  color: #fff;
  padding: 16px;
  padding: 0.5rem 1rem;
  font-weight: 600;
}

.dropdown-link:hover,
.dropdown-link:focus,
.dropdown-link:active {
  text-decoration: none; /* Ensures no underline on interaction */
  color: #fff; /* Maintain consistent text color */
  outline: none; /* Removes focus outline */
}

@media screen and (max-width: 960px) {
  .fa-caret-down3 {
    display: none;
  }
}
