/* General container styling */
.arvr-property-design-container {
  font-family: 'Arial', sans-serif;
  color: #333;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Header styling */
.arvr-header {
  text-align: center;
  margin-bottom: 40px;
}

.arvr-header h1 {
  font-size: 2.5rem;
  color: #003d6c;
  margin-bottom: 10px;
  animation: fadeIn 1s ease-out;
}

.arvr-header p {
  font-size: 1.2rem;
  color: #555;
  animation: fadeIn 1.5s ease-out;
}

/* Section styling */
.design-overview {
  text-align: center;
  margin-bottom: 40px;
}

.design-overview h2 {
  font-size: 2rem;
  color: #003d6c;
  margin-bottom: 20px;
}

.design-content {
  position: relative;
}

/* Step content styling */
.design-step {
  width: 600px;
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease, opacity 0.5s ease;
  animation: fadeInUp 1s ease-out;
  margin-left: 40vh;
}

.design-image {
  height: 300px;
  width: 500px;
}

.navigation-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.nav-button {
  background-color: #003d6c;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.5rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.nav-button:hover {
  background-color: #002f5d;
  transform: scale(1.1);
}

/* Benefits Section Styling */
.benefits-section {
  text-align: center;
  margin-bottom: 40px;
}

.benefits-section h2 {
  font-size: 2rem;
  color: #003d6c;
  margin-bottom: 20px;
}

.benefits-list {
  list-style: none;
  padding: 0;
}

.benefits-list li {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

/* CTA Section Styling */
.cta-section {
  text-align: center;
  margin-top: 40px;
}

.cta-section h2 {
  font-size: 2rem;
  color: #003d6c;
  margin-bottom: 20px;
}

.cta-button {
  background-color: #003d6c;
  color: #fff;
  border: none;
  padding: 15px 30px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
  background-color: #002f5d;
  transform: scale(1.05);
}

/* Keyframes for animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive design for small screens */
@media (max-width: 370px) {
  .design-step {
    margin-left: 0;
    width: 300px; 
  }

  .design-image {
    width: 200px;
    height: 200px;
  }
}
