/* Service buttons */
.service button {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #3498db;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;
}

.service button:hover {
  background-color: #2980b9;
}

/* Active service button */
.service button.active {
  background-color: #2ecc71;
}

/* Popular Localities section */
.localities-container {
  margin-top: 20px;
}

.localities-container button {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.localities-container button.selected {
  background-color: #2ecc71;
  color: #fff;
  border: 1px solid #2ecc71;
}

/* Other sections (Property types, Bedrooms, Fetch Property button) */
.other-sections {
  margin-top: 20px;
}

.other-sections button {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.other-sections button.selected {
  background-color: #2ecc71;
  color: #fff;
  border: 1px solid #2ecc71;
}

/* Fetch Property button */
.fetch-property-button {
  margin-top: 20px;
}

.fetch-property-button button {
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  background-color: #3498db;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.fetch-property-button button:hover {
  background-color: #2980b9;
}

.localities-container {
  margin-top: 20px;
}

.localities-container button {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.localities-container button.selected {
  background-color: #3498db;
  color: #fff;
}

.localities-container button:hover {
  background-color: #2980b9;
}
.popup-cont{
  border: solid #0a0909 2px;
  border-radius: 5px;
  padding: 15px;
  width: 300px;
  background-color: aliceblue;
  margin: 15px;
}