/* Main container styling */
.cont-team {
  padding: 40px;
  background: linear-gradient(135deg, #e8f4fd 0%, #cfe0f4 100%);
}

.team-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

/* Headings and text */
h1 {
  font-size: 2.8em;
  color: #0b3d91; /* Dark professional blue */
  margin-bottom: 20px;
}

.team-sub {
  font-size: 1.2em;
  color: #5d6d7e;
  margin-bottom: 50px;
  line-height: 1.6;
}

/* Company message styling */
.company-message {
  background: rgba(255, 255, 255, 0.9); /* White with slight transparency */
  padding: 30px;
  border-radius: 15px;
  margin-bottom: 40px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.company-message h1 {
  font-size: 2.5em;
  color: #0b3d91;
  margin-bottom: 15px;
}

.company-message p {
  font-size: 1.2em;
  color: #5d6d7e;
  line-height: 1.8;
  margin-bottom: 20px;
}

.company-message ul {
  margin: 20px 0;
  padding-left: 20px;
  list-style: disc;
}

.company-message ul li {
  font-size: 1.1em;
  color: #2e86de;
  margin-bottom: 10px;
}

.company-message strong {
  color: #0b3d91;
}

/* Team category styling */
.team-category {
  margin-bottom: 60px;
}

.team-category h2 {
  font-size: 2.2em;
  margin-bottom: 30px;
  color: #154360; /* Medium blue for section headers */
}

.company-heading{
  text-align: center;
  color: #154360;
  margin-bottom: 10px;
}

/* Team members grid */
.team-members {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 40px;
  justify-items: center;
}

/* Glassy team member card */
.team-member {
  background: rgba(30, 135, 221, 0.286); /* Transparent blue for glassy effect */
  border-radius: 15px;
  padding: 30px;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  border: 1px solid rgba(33, 150, 243, 0.3); /* Soft blue border */
}

.team-member:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

/* Team member photo */
.team-member-photo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
  border: 4px solid rgba(33, 150, 243, 0.5); /* Blue outline around photo */
}

/* Team member name and role */
.team-member h3 {
  font-size: 1.6em;
  margin-bottom: 10px;
  color: #1f4e79; /* Darker blue for names */
}

.team-member p {
  font-size: 1.1em;
  color: #2e86de; /* Lighter blue for roles */
}

/* LinkedIn icon */
.linkedin-icon {
  width: 32px;
  margin-top: 15px;
  transition: transform 0.3s ease;
}

.linkedin-icon:hover {
  transform: scale(1.1);
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  h1 {
    font-size: 2.4em;
  }

  .company-message h1 {
    font-size: 2.2em;
  }

  .company-message p {
    font-size: 1.1em;
  }

  .team-category h2 {
    font-size: 2em;
  }

  .team-member {
    padding: 20px;
  }

  .team-member-photo {
    width: 100px;
    height: 100px;
  }

  .team-member h3 {
    font-size: 1.4em;
  }

  .team-member p {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .company-message {
    padding: 20px;
  }

  .company-message h1 {
    font-size: 2em;
  }

  .company-message p {
    font-size: 1em;
  }

  .team-member-photo {
    width: 90px;
    height: 90px;
  }

  .team-member h3 {
    font-size: 1.3em;
  }

  .team-member p {
    font-size: 0.9em;
  }
}
