.carrer-main-cont{
    padding: 10px;
    background: linear-gradient(90deg, rgb(7, 17, 68) 3%, rgb(65, 68, 163) 76%, rgb(113, 121, 246) 100%);
}
.career-page {
    max-width: 800px;
    padding-top: 10px;
    margin: 0 auto;
    padding: 20px;
    background: linear-gradient(135deg, #87bde4, #2c3e50);
    color: white;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .filter-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .filter-tabs button {
    background-color: #eee;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .filter-tabs button.active {
    background-color: #007bff;
    color: white;
  }
  
  .job-list {
    display: grid;
    gap: 20px;
  }
  
  .job {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    display: flex; /* Added to display items in a row */
    justify-content: space-between;
    align-items: center; /* Added for vertical alignment */
    
  }
  
  .job h3 {
    margin-right: 10px; /* Added margin for spacing between title and department */
  }
  
  .job p {
    margin: 0;
  }
  
  .job button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .job button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .career-page {
      padding: 10px;
    }
  
    .filter-tabs {
      flex-wrap: wrap;
    }
  
    .filter-tabs button {
      margin: 5px;
    }
  
    .job-list {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }
  