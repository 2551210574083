/* index.css */

/* Basic reset and container styling */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  color: #333;
}

.iot-solutions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f4f7f9; /* Light gray background for contrast */
}

header.iot-header {
  text-align: center;
  background: linear-gradient(to right, #004d40, #00796b); /* Professional gradient */
  color: #fff;
  padding: 40px;
  border-radius: 8px;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}

header.iot-header h1 {
  font-size: 2rem;
  margin: 0;
}

header.iot-header p {
  font-size: 1.1rem;
  margin-top: 10px;
}

.iot-solutions-overview {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.iot-solutions-overview h2 {
  text-align: center;
  font-size: 2rem;
  color: #00796b;
  margin-bottom: 20px;
}

.iot-solutions-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.iot-solution-step {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.iot-solution-step:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.iot-solution-step h3 {
  color: #004d40;
  font-size: 1.5rem;
}

.benefits-section {
  background-color: #00796b;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.benefits-section h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.benefits-list {
  list-style-type: none;
  padding: 0;
  font-size: 1.1rem;
}

.benefits-list li {
  padding: 10px;
  border-bottom: 1px solid #fff;
}

.benefits-list li:last-child {
  border-bottom: none;
}

.cta-section {
  text-align: center;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
}

.cta-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.cta-section p {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.cta-button {
  background-color: #004d40;
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
  background-color: #00332e;
  transform: scale(1.05);
}
