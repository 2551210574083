.wrap-cont{
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .wrap-cont{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.technology-container {
  text-align: center;
  font-family: 'PT Sans', sans-serif; 
}

.main-heading {
  margin-top: 30px;
  font-size: 2em;
  padding-bottom: 5x;
}

.sub-heading {
  margin-bottom: 20px;
  color: #666;
  text-align: center;
}

.tech-sections {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin: 5px;
}
.tech-sections-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin: 5px;
}

.tech-image {
  position: relative;
  width: 400px;
  height: 250px;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}

.tech-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.tech-text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.tech-text-overlay h2 {
  margin-bottom: 10px;
  font-size: 1.5em;
  color: aliceblue;
}

.tech-text-overlay p {
  opacity: 1;
  color: wheat;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: opacity 0.3s ease;
}

.tech-image:hover img {
  transform: scale(1.1);
}

.tech-image:hover .tech-text-overlay {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tech-image.clicked .tech-text-overlay p {
  opacity: 1;
  white-space: normal;
  overflow: visible;
}
.tech-sections p {
  margin-top: 10px;
  line-height: 1.6; 
  font-size: 16px; 
  color: #006ac2;
}


.wrap-cont {
  display: flex;
  justify-content: space-between; /* Adjust the spacing between sections */
}

/* Each tech section */
.tech-sections {
  position: relative;
  width: 45%; /* Adjust width as needed */
  margin-bottom: 20px; /* Spacing between sections */
}

.tech-image {
  position: relative;
}

/* Overlay for text content */
.tech-text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: transparent; /* Example background color */
}

/* Centered content within overlay */
.centered-content {
  padding: 20px;
}

.tech-image {
  width: 100%; 
  max-width: 400px; 
  height: auto; 
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}
 
.tech-image-3{
 
  max-width: 400px; /* Limit image size for larger screens */
  height: 300px; /* Maintain aspect ratio */
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}
.tech-text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  opacity: 1;
  transition: opacity 0.3s ease;
}
.btn-ai{
  background-color: #666;
  border-radius: 5px;
}

.tech-sections {
  width: 45%; 
  margin-bottom: 20px; 
  min-width: 300px; 
  transition: height 0.3s ease; 
  overflow: hidden; 
}

.prop-text-service-description {
  max-height: 100px;
  overflow: hidden; 
  transition: max-height 0.3s ease; 
}

.prop-text-service-description.expand {
  max-height: none; 
}

.btn-ai {
  background-color: #666;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
  cursor: pointer;
}

.animated-list-container {
  margin-top: 20px;
  text-align: center; 
}

.cont-101 {
  margin-top: 20px;
  text-align: left; 
}

.list-item {
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
  line-height: 1.5;
  font-size: 1.1rem;
  list-style: decimal;
  text-align: left;
}

.list-item::before {
  position: absolute;
  left: -20px;
  top: 0;
  background-color: #3498db;
  color: white;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  font-size: 0.9rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .tech-sections {
    width: 90%; 
    max-width: 500px;
  }
}

.tech-sections {
  width: 50%; 
  margin-bottom: 5px; 
  min-width: 380px; 
  height: 400px; 
  overflow: hidden; 
}

.prop-text-service-description {
  max-height: 100px; 
  overflow: hidden; 
  transition: max-height 0.3s ease; 
}

.prop-text-service-description.expand {
  max-height: none; 
}


@media screen and (max-width: 768px) {
  .tech-sections {
    width: 90%; 
    max-width: 500px; 
    height: auto; 
  }
}

.aj{
  display: flex;
  flex-direction: column; 
  width: 45%; 
  margin-bottom: 5px; 
  min-width: 300px;
}

.tech-heading{
  color: #333;
}