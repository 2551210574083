.dropdown-menu4 {
    background: #006ac2;
    width: 200px;
    position: absolute;
    top: 100%;
    list-style: none;
    text-align: start;
    z-index: 1000;
  }
  
  .dropdown-menu4 li {
    background: #006ac2;
    cursor: pointer;
  }
  
  .dropdown-menu4 li:hover {
    background: #5cabff;
  }
  
  .dropdown-menu4.clicked {
    display: none;
  }
  
  .dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none; 
    color:  #fff;
    padding: 16px;
    padding: 0.5rem 1rem;
    font-weight: 600;
  }
  
  @media screen and (max-width: 960px) {
    .fa-caret-down3 {
      display: none;
    }
  }