/* index.css */

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f4f4f4;
}

.city-state-selection {
  padding: 20px;
  text-align: center;
}

.city-state-selection h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.city-state-selection h2 {
  font-size: 2rem;
  color: #555;
  margin-top: 30px;
  margin-bottom: 10px;
}

.location-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
  padding: 0 20px;
}

.location-item {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.location-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.location-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-bottom: 2px solid #ddd;
}

.location-item p {
  font-size: 1.2rem;
  color: #444;
  margin: 15px 0;
}

@media (max-width: 768px) {
  .location-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
  }

  .location-item img {
    height: 120px;
  }

  .location-item p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .location-grid {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
  }

  .location-item img {
    height: 100px;
  }

  .location-item p {
    font-size: 0.9rem;
  }
}
