
.menus {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    font-family: 'PT Sans', sans-serif;
  }
  
  .menu-items {
    position: relative; 
    color: white;
    font-size: 18px; 
    font-weight: 500;
    font-family: 'PT Sans', sans-serif;
    margin-left: 20px;
  }
  
  .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none; 
    font-family: 'PT Sans', sans-serif;
  }
  
  .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer; 
    font-family: 'PT Sans', sans-serif;
    width: 100%;
  }
  
  button span {
    margin-left: 3px;
  }
  
  .menu-items > a, .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }
  
  .menu-items a:hover,
  .menu-items button:hover {
    background-color: #000000;
  }
  
  .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  