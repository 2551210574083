.dropdown-menu {
  background: #006ac2;
  width: 200px;
  position: absolute;
  top: 100%;
  list-style: none;
  text-align: start;
  z-index: 1;
}

.dropdown-menu li {
  background: #006ac2;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #5cabff;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color:  #fff;
  padding: 16px;
  padding: 0.5rem 1rem;
  font-weight: 600;
}

@media screen and (max-width: 960px) {
  .fa-caret-down3 {
    display: none;
  }
}


/* Main menu styles */
.dropdown-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.menu-item {
  position: relative;
}

/* Submenu styles */
.dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%; 
  list-style: none;
  background-color: #fff;
  padding: 0;
  display: none; 
  z-index: 1000;
}

.menu-item:hover .dropdown-submenu {
  display: block; 
}

.submenu-item {
  padding: 10px 15px;
  white-space: nowrap; 
}

.submenu-item .dropdown-sublink {
  color: white;
  text-decoration: none;
}

.submenu-item .dropdown-sublink:hover {
  color: #007bff; /* Adjust hover color */
}
