/* index.css */

.payment-gate-cont{
  background-color: #007bff;
}

.payment-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
 
}

.payment-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.payment-container p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
}

.payment-form {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  margin-top: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.payment-form h2 {
  font-size: 1.8rem;
  margin-bottom: 30px;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: #555;
}

input[type="text"],
select {
  width: calc(100% - 20px);
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

input[type="text"]:focus,
select:focus {
  outline: none;
  border-color: #007bff;
}

.pay-button,
.digital-pay-now {
  display: block;
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 6px;
  background-color: #007bff;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pay-button:hover,
.digital-pay-now:hover {
  background-color: #0056b3;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .payment-form {
    padding: 30px;
  }
  input[type="text"],
  select {
    width: 100%;
  }
}


#payment-heading{
  color: white;
  font-family: 'Arial', sans-serif;
font-size: 35px;
}
#pay-des{
  color: white;
  font-weight: 550;
  font-family: 'Arial', sans-serif;
}

.qr-img{
  width: 70%;
}